import React, { useEffect, useState } from 'react'
import s from "./App.module.sass"
import logo from "./images/logo.png"
import slide_1 from "./images/1.jpeg"
import slide_2 from "./images/2.jpeg"
import slide_3 from "./images/3.jpeg"
import slide_4 from "./images/4.jpeg"
import slide_5 from "./images/5.jpeg"
import { YMaps, Map } from '@pbe/react-yandex-maps';

function App() {

    const [srcImg, setSrcImg] = useState(slide_1)

    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [date, setDate] = useState("")

    const handleNameChange = (e) => {
        let value = e.target.value
        setName(value)
    }
    const handlePhoneChange = (e) => {
        let value = e.target.value
        setPhone(value)
    }
    const handleDateChange = (e) => {
        let value = e.target.value
        setDate(value)
    }


    const sendData = (e) => {

    }


    useEffect(() => {



        let slideImg = document.getElementById("slideImg")
        let numberOfSlide;
        setInterval(() => {
            numberOfSlide = slideImg.getAttribute("src")
            if (numberOfSlide === slide_1) {
                slideImg.classList.add(s.animatedImg)
                setTimeout(() => {
                    setSrcImg(slide_2);
                }, 600)
                setTimeout(() => {
                    slideImg.classList.remove(s.animatedImg)
                }, 1200)
            } else if (numberOfSlide === slide_2) {
                slideImg.classList.add(s.animatedImg)
                setTimeout(() => {
                    setSrcImg(slide_3);
                }, 600)
                setTimeout(() => {
                    slideImg.classList.remove(s.animatedImg)
                }, 1200)
            } else if (numberOfSlide === slide_3) {
                slideImg.classList.add(s.animatedImg)
                setTimeout(() => {
                    setSrcImg(slide_4);
                }, 600)
                setTimeout(() => {
                    slideImg.classList.remove(s.animatedImg)
                }, 1200)
            } else if (numberOfSlide === slide_4) {
                slideImg.classList.add(s.animatedImg)
                setTimeout(() => {
                    setSrcImg(slide_5);
                }, 600)
                setTimeout(() => {
                    slideImg.classList.remove(s.animatedImg)
                }, 1200)
            } else if (numberOfSlide === slide_5) {
                slideImg.classList.add(s.animatedImg)
                setTimeout(() => {
                    setSrcImg(slide_1);
                }, 600)
                setTimeout(() => {
                    slideImg.classList.remove(s.animatedImg)
                }, 1200)
            }
        }, 3500);
    }, [])




    return (
        <div className={s.container}>
            <div className={s.header}>
                <img src={logo} alt="" />
            </div>
            <div className={s.slider}>
                <img src={srcImg} alt="" id='slideImg' />
            </div>

            <div className={s.information}>
                <div className={s.blockOut}>
                    <div className={s.blockInner}>
                        <div className={s.logo}>
                            <img src={logo} alt="logo" />
                        </div>
                        <div className={s.mainText}>Парикхмахерская Наш Стилист в Караганде</div>
                        <div className={s.parText}>Стрижки: от 1500тг Акция10я - бонус 1000тг.<br/>ЕЖЕМЕСЯЧНО НАС ВЫБИРАЮТ БОЛЕЕ 20000 ЧЕЛОВЕК <br></br>Наш Стилист — это семейная парикмахерская. В 2023году мы возродили традиции классических  стрижек Как у нас получилось? Да легко.Мы просто стрижем - но делаем это лучше всех.Высочайшее качество и доступные цены – это визитная карточка Сети салонов «Наш Стилист»</div>
                    </div>
                </div>
            </div>


            <div className={s.price}>
                <div className={s.inner}>
                    <div className={s.leftSide}>
                        <div>Мужская стрижка</div>
                        <div>Женская стрижка</div>
                        <div>Детская стрижка</div>
                        <div>Укладка</div>
                        <div>Окрашивание</div>
                        <div>Био-химия</div>
                    </div>

                    <div className={s.rightSide}>
                        <div>от 2000тг</div>
                        <div>от 2500тг</div>
                        <div>(до 12 лет) от 1500тг</div>
                        <div>от 3000тг</div>
                        <div>от 5000тг</div>
                        <div>от 8000тг</div>
                    </div>
                </div>
            </div>



            <div className={s.links}>
                <div className={s.inner}>
                    <button className={s.whatsappBtn} onClick={() => window.location.href = "https://wa.me/+77074949849"}>Написать в Whatsapp</button>
                    <a className={s.phoneNumber} href="tel:+77074949849">8707-49-49-849</a>
                    <button className={s.scrollDownBtn} onClick={() => {window.location.href = "https://b829665.alteg.io/company/778791/menu?o=s10903115"}}>Онлайн Запись</button>
                </div>
                <button className={s.telega} onClick={() => {window.location.href = "https://t.me/Our_stylist"}}>Телеграм</button>
            </div>


            <div className={s.carta}>
                <div className={s.cart} id='carta'>
                    <h3>Наши адреса:</h3>
                    <a href="https://yandex.ru/maps/?um=constructor%3A6465c0a06696118f1aa0e2e8cbe808ab79a50bd3951871141fdf524e59a49c96&amp;source=constructorStatic" target="_blank"><img src="https://api-maps.yandex.ru/services/constructor/1.0/static/?um=constructor%3A6465c0a06696118f1aa0e2e8cbe808ab79a50bd3951871141fdf524e59a49c96&amp;width=500&amp;height=400&amp;lang=ru_RU" alt="" /></a>
                </div>

                {/* <div className={s.form}>
                    <div className={s.innerForm}>
                        <h3>Онлайн Запись</h3>
                        <input type="text" placeholder='Ваше имя: ' onChange={(e) => handleNameChange(e)} />
                        <input type="text" placeholder='Номер телефона: ' onChange={(e) => handlePhoneChange(e)} />
                        <input type="date" placeholder='Дата: ' onChange={(e) => handleDateChange(e)} />
                        <button onClick={(e) => {sendData(e)}}>Отправить заявку</button>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default App